export default defineI18nConfig(() => ({
  legacy: false,
  fallbackLocale: 'en',
  messages: {
    cs: {
      head: {
        description: 'Pořiďte si CZKC (Czech Crypto Crown) testovací token pro vývojářské účely.',
      },
      nav: {
        about: 'O NÁS',
        faucet: 'FAUCET',
        docs: 'DOKUMENTACE',
        termsOfUse: 'PODMÍNKY POUŽITÍ',
        privacyPolicy: 'GDPR',
        aboutCZKC: 'O CZKC',
      },
      description: {
        item1: 'Pro vaši aplikaci či školení',
        item2: 'Rychle, snadno a bez povolení',
        item3: 'Podpora několika blockchainů',
        learnMore: 'DOZVĚDĚT SE VÍCE',
      },
      email: {
        subject: 'Vyzvedněte si svůj JEDEN MILION CZKC! 🤑',
        message: 'Zde je odkaz pro vyzvednutí vašich CZKC: ',
      },
      formSection: {
        forCommonUse: 'pro běžné použití',
        forDeFi: 'pro DeFi',
        email: 'E-mail',
        chooseNetwork: 'Vyberte síť',
        sendTo: 'Poslat na',
        walletAddress: 'Adresa peněženky',
        followUs: 'Sledujte nás',
        follow: 'Sledovat CZKC na',
        dontFollow: 'Nesledovat CZKC',
        send: 'POSLAT',
        description1: 'Nemáte testnet',
        description1a: '?',
        description2: 'Získejte je z faucetu',
        need: 'Potřebujete',
        help: 'POMOC?',
        send500Succesfull: 'Na zadanou adresu bylo odesláno 500 CZKC.',
        send1MEmailSuccesfull: 'Na zadaný e-mail byl odeslán odkaz pro vyzvednutí CZKC.',
        send1MSuccesfull: 'Na zadanou adresu byl odeslán jeden milion CZKC.',
        showTx: 'Zobrazit transakci',
        openMail: 'Otevřít e-mailovou aplikaci',
      },
      descriptionSide: {
        appName: 'Testnet Faucet',
        banner1: 'Czech',
        banner2: 'Crypto',
        banner3: 'Crown',
      },
      validation: {
        use: 'Typ použití je povinný.',
        email: 'E-mail je povinné pole.',
        emailInvalid: 'E-mail má neplatný formát.',
        network: 'Síť je povinné pole.',
        wallet: 'Adresa peněženky je povinné pole.',
        walletInvalid: 'Adresa peněženky má neplatný formát.',
        socials: 'Vyberte, zda chcete sledovat CZKC na sociálních sítích.',
      },
      error: {
        faucetDisabled: 'Faucet je dočasně vypnutý.',
        faucetIsDrained: 'Faucet je vyčerpán, není dostatek tokenů pro váš požadavek.',
        addressAlreadyUsed: 'Dnes jste již dostali maximální množství CZKC.',
        transferFailed: 'Převod tokenů selhal.',
        emailAlreadyUsed: 'Tento e-mail již byl použit.',
        badRequest: 'Požadavek se nepodařilo zpracovat.',
        mailtoNotFound: 'Odkaz pro tento e-mail neexistuje.',
        unknownError: 'Něco se pokazilo.',
        permissionDenied: 'Je třeba povolit přístup k fotoaparátu a obnovit stránku.',
      },
    },
    en: {
      head: {
        description: 'Get some CZKC (Czech Crypto Crown) testnet tokens for development purposes.',
      },
      nav: {
        about: 'ABOUT',
        faucet: 'FAUCET',
        docs: 'DOCS',
        termsOfUse: 'TERMS OF USE',
        privacyPolicy: 'PRIVACY POLICY',
        aboutCZKC: 'ABOUT CZKC',
      },
      description: {
        item1: 'For your app or courses',
        item2: 'Fast, easy, and permissionless',
        item3: 'Support for multiple blockchains',
        learnMore: 'LEARN MORE',
      },
      email: {
        subject: 'Claim your ONE MILLION CZKC! 🤑',
        message: 'Here is a link to claim your CZKC: ',
      },
      formSection: {
        forCommonUse: 'for common use',
        forDeFi: 'for DeFi',
        email: 'Email',
        chooseNetwork: 'Choose network',
        sendTo: 'Send to',
        walletAddress: 'Wallet address',
        followUs: 'Follow us',
        follow: 'Follow CZKC on',
        dontFollow: 'Don\'t follow CZKC',
        send: 'SEND',
        description1: 'Need more testnet',
        description1a: '?',
        description2: 'Get it from faucet',
        need: 'Need',
        help: 'HELP?',
        send500Succesfull: '500 CZKC have been sent to the given address.',
        send1MEmailSuccesfull: 'Claim link been sent to the given email address.',
        send1MSuccesfull: 'One milion CZKC have been sent to the given address.',
        showTx: 'Show transaction',
        openMail: 'Open email client',
      },
      descriptionSide: {
        appName: 'Testnet Faucet',
        banner1: 'Czech',
        banner2: 'Crypto',
        banner3: 'Crown',
      },
      validation: {
        use: 'Usage field is required.',
        email: 'Email field is required.',
        emailInvalid: 'Email is in invalid format.',
        network: 'Network field is required.',
        wallet: 'Wallet address field is required.',
        walletInvalid: 'Wallet address is in invalid format.',
        socials: 'Choose whether to follow CZKC on social media.',
      },
      error: {
        faucetDisabled: 'Faucet is temporarily disabled.',
        faucetIsDrained: 'Faucet is drained, there is not enough tokens for yoru request.',
        addressAlreadyUsed: 'You already claimed maximum of CZKC today.',
        transferFailed: 'Failed to transfer tokens.',
        emailAlreadyUsed: 'This email was already used.',
        badRequest: 'Request couldn\'t be processed.',
        mailtoNotFound: 'The claim link for this email doesn\'t exist.',
        unknownError: 'Something broke.',
        permissionDenied: 'You need to grant access to the camera and reload the page.',
      },
    },
  },
}))
